import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopicsComponent } from './topics/topics.component';
import { SectionsComponent } from './sections/sections.component';
import { BurgerMenuComponent } from './burger-menu/burger-menu.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { navReducer } from './nav.reducer';
import { NAV_STORE_KEY } from './nav.share';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NavEffects } from './nav.effects';

@NgModule({
  declarations: [
    TopicsComponent,
    SectionsComponent,
    BurgerMenuComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    MatButtonModule, MatMenuModule, MatIconModule,
    StoreModule.forFeature(NAV_STORE_KEY, navReducer),
    EffectsModule.forFeature([NavEffects])
  ],
  exports: [
    TopicsComponent,
    SectionsComponent,
    BurgerMenuComponent
  ]
})
export class NavModule { }

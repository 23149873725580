import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap, tap } from 'rxjs/operators';
import { changeSection } from './nav.actions';

@Injectable()
export class NavEffects {

  navigateToSection$ = createEffect(() => this.actions$.pipe(
    ofType(changeSection),
    tap(action => {
      console.log("effet !");
      this.router.navigateByUrl(action.newRoute);
    }),
    catchError(error => {
      console.error('Navigation Error:', error);
      return of();
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router
  ) {}
}

import { NAV_STORE_KEY } from "./nav.share";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INavState } from "./nav.state";

export const selectNavState = createFeatureSelector<INavState>(NAV_STORE_KEY);

export const selectTopicId = createSelector(
  selectNavState,
  (state: INavState) => state.idTopic
);

// Vous pouvez créer des sélecteurs supplémentaires de manière similaire

import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest, map, of } from 'rxjs';
import { INavItem, SectionIds, TopicIds } from '../nav.share';
import { RoleCodes } from '../../user/user.share';
import { Store } from '@ngrx/store';
import { selectRoleCodes } from '../../user/user.selectors';
import { selectTopicId } from '../nav.selectors';
import { changeSection } from '../nav.actions';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrl: './sections.component.scss'
})
export class SectionsComponent implements OnInit {
  roles$!: Observable<string[]>;
  filteredSections$!: Observable<INavItem[]>;
  selectedTopicId$!: Observable<TopicIds | SectionIds>;
  activeSection!: string;

  // ------------------------------------------ définition des sections
  sections: INavItem[] = [
    {
      id: SectionIds.Section_Utilisateurs,
      parentId: TopicIds.Topic_Admin,
      label: 'Utilisateurs',
      subLabel: "",
      subLabelClass: "",
      route: "users",
      rolesAllowed: [
        RoleCodes.ADMIN.toString(),
      ]
    },
    {
      id: SectionIds.Section_Locataires,
      parentId: TopicIds.Topic_Location,
      label: 'Locataires',
      subLabel: "",
      subLabelClass: "",
      route: "",
      rolesAllowed: [
        RoleCodes.ADMIN.toString(),
        RoleCodes.GEST.toString()
      ]
    },
    {
      id: SectionIds.Section_Logement,
      parentId: TopicIds.Topic_Patrimoine,
      label: 'Logements',
      subLabel: "",
      subLabelClass: "",
      route: "",
      rolesAllowed: [
        RoleCodes.ADMIN.toString(),
        RoleCodes.GEST.toString()
      ]
    },
  ];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.roles$ = this.store.select(selectRoleCodes);
    this.selectedTopicId$ = this.store.select(selectTopicId);

    this.filteredSections$ = combineLatest([this.roles$, of(this.sections), this.selectedTopicId$]).pipe(
      map(([roles, sections, selectedTopicId]) =>
        sections.filter(section =>
          section.parentId === selectedTopicId &&
          (section.rolesAllowed ? section.rolesAllowed.some(r => roles.includes(r)) : true) &&
          (section.rolesDenied ? !section.rolesDenied.some(r => roles.includes(r)) : true)
        )
      )
    );
  }

  onClicked(section: INavItem): void {
    this.activeSection = section.id;
    const route: string = section.route || '';
    this.store.dispatch(changeSection({ newSection: section.id, newRoute: route }));
  }
}

<div class="for-desktop" *ngIf="isDesktopView">
  <header>
    <div class="header-left">
      <div class="title-bar"><h1>Alfred</h1></div>
    </div>
    <div class="header-center">
      <app-topics></app-topics>
    </div>
    <div class="header-face">
      <!--<app-user-face></app-user-face>-->
    </div>
    <div class="header-right">
      <app-burger-menu></app-burger-menu>
    </div>
  </header>
  <div class="main-content">
    <div class="main-content-left">
      <app-sections></app-sections>
    </div>
    <div class="main-content-center">
      <router-outlet></router-outlet>
    </div>
    <div class="main-content-right"></div>
  </div>
  <footer>
    <div>
      <p>{{ version }} - {{ isDevMode }}</p>
    </div>
  </footer>  
</div>
<div class="for-mobile" *ngIf="!isDesktopView">
  <header class="header-for-mobile">
    <div class="header-top-mobile">
      <!--<app-user-face></app-user-face>-->
      <!--<app-burger-menu></app-burger-menu>-->
    </div>
    <div class="header-bottom-mobile">
      <div class="title-bar"><h1>Alfred</h1></div>
    </div>
  </header>
  <div class="main-content">
    <div class="main-content-center">
      <router-outlet></router-outlet>
    </div>
    <div class="main-content-right"></div>
  </div>
  <div>
    <app-sections></app-sections>
  </div>
  <div class="topics-for-mobile">
    <app-topics></app-topics>
  </div>
  
  <footer>
    <div>
      <p>{{ version }}</p>
    </div>
  </footer>  
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormUserLoginComponent } from './form-user-login/form-user-login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpClientModule } from '@angular/common/http';
import { USER_STORE_KEY } from './user.share';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { userReducer } from './user.reducers';
import { UserListComponent } from './user-list/user-list.component';

@NgModule({
  declarations: [
    FormUserLoginComponent,
    UserListComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    StoreModule.forFeature(USER_STORE_KEY, userReducer),
    EffectsModule.forFeature([])
  ],
  exports: [
    FormUserLoginComponent,
    UserListComponent
  ]
})
export class UserModule { }

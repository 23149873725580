import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, map, of } from 'rxjs';
import { selectRoleCodes } from '../../user/user.selectors';
import { INavItem, TopicIds } from '../nav.share';
import { RoleCodes } from '../../user/user.share';
import { changeTopic } from '../nav.actions';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrl: './topics.component.scss'
})
export class TopicsComponent implements OnInit {
  roles$!: Observable<string[]>;
  filteredTopics$!: Observable<INavItem[]>;
  selectedTopicId!: string;

  // ------------------------------------------ définition des topics
  topics: INavItem[] = [
    {
      id: TopicIds.Topic_Location,
      label: 'Location',
      labelStyles: {},    //{ borderBottom: '2px solid #fff' }
      rolesAllowed: [
        RoleCodes.LOCAT.toString(),
        RoleCodes.ADMIN.toString(),
        RoleCodes.GEST.toString()
      ]
    },
    {
      id: TopicIds.Topic_Patrimoine,
      label: 'Patrimoine',
      labelStyles: {},    //{ borderBottom: '2px solid #fff' }
      rolesAllowed: [
        RoleCodes.ADMIN.toString(),
        RoleCodes.GEST.toString()
      ]
    },
    {
      id: TopicIds.Topic_Admin,
      label: 'Administration',
      labelStyles: {},
      rolesAllowed: [
        RoleCodes.ADMIN.toString()
      ]
    }
  ];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.roles$ = this.store.select(selectRoleCodes);

    this.filteredTopics$ = combineLatest([this.roles$, of(this.topics)]).pipe(
      map(([roles, topics]) =>
        topics.filter(topic =>
          (topic.rolesAllowed ? topic.rolesAllowed.some(r => roles.includes(r)) : true) &&
          (topic.rolesDenied ? !topic.rolesDenied.some(r => roles.includes(r)) : true)
        )
      )
    );
  }

  onClicked(topic: INavItem) {
    console.log(topic);
    this.store.dispatch(changeTopic({ newTopic: topic.id }));
  }
}

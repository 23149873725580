export interface IUserState {
    username: string |null;
    idsession: string | null;
    rolecodes: string[];
}

export const initialUserState: IUserState = {
    username: null,
    idsession: null,
    rolecodes: []
}

/*
  ___ ___                             _______                          __                
 |   Y   | .-----. .-----. .----.    |   _   | .-----. .----. .--.--. |__| .----. .-----.
 |.  |   | |__ --| |  -__| |   _|    |   1___| |  -__| |   _| |  |  | |  | |  __| |  -__|
 |.  |   | |_____| |_____| |__|      |____   | |_____| |__|    \___/  |__| |____| |_____|
 |:  1   |                           |:  1   |                                           
 |::.. . |                           |::.. . |                                           
 `-------'                           `-------'                                           
                                                                                         
*/

import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ILoginRequestParams, ILoginResponse } from '../user-login.share';
import { Store } from '@ngrx/store';
import { loginSuccess } from '../user.actions';
import { USER_STORE_KEY } from '../user.share';
import { IUserState } from '../user.state';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  isLoggedIn$: Observable<boolean>;

  constructor(
    private http: HttpClient,
    private store: Store<{ [USER_STORE_KEY]: IUserState }>
  ) {
    // observable pour suivre si l'utilisateur est connecté
    this.isLoggedIn$ = this.store.select(USER_STORE_KEY).pipe(
      map((userState: IUserState) => !!userState?.idsession)
    );
  }

  /*
    _                   _         
  | |   ___     __ _  (_)  _ __  
  | |  / _ \   / _` | | | | '_ \ 
  | | | (_) | | (_| | | | | | | |
  |_|  \___/   \__, | |_| |_| |_|
                |___/             
  */

  login(username: string, password: string): Observable<ILoginResponse> {
    const loginParams: ILoginRequestParams = {
      username: username,
      password: password
    }
    return this.http.post<ILoginResponse>(
      environment.api1.connexion.url + environment.api1.routes.login, 
      loginParams
    );
  }

  handleLoginResponse(response: ILoginResponse) {
    // Dispatch an action with the login response data
    this.store.dispatch(loginSuccess({
      username: response.username,
      idsession: response.idsession,
      rolecodes: response.rolecodes
    }));
  }
}

import { Action, createAction, props } from "@ngrx/store";

export const changeTopic = createAction(
    '[Nav] Change Topic',
    props<{ newTopic: string }>()
);

export const changeSection = createAction(
    '[Nav] Change Section',
    props<{ newSection: string, newRoute: string }>()
);

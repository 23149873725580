import { Action, createReducer, on }                           from "@ngrx/store";
import { INavState, initialNavState }                  from './nav.state';
import { SectionIds, TopicIds } from "./nav.share";
import { changeSection, changeTopic } from "./nav.actions";

const reducer = createReducer(
  initialNavState,
  on(changeTopic, (state, { newTopic }) => ({
    ...state,
    idTopic: newTopic as TopicIds | SectionIds
  })),
  on(changeSection, (state, { newSection, newRoute }) => ({
    ...state,
    idSection: newSection as TopicIds | SectionIds,
    route: newRoute
  }))
);

export function navReducer(state: INavState | undefined, action: Action) {
  return reducer(state, action);
}

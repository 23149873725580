{
  "name": "alfredapp1d",
  "version": "0.0.1d",
  "scripts": {
    "ng": "ng",
    "start": "nx serve",
    "build": "nx build",
    "watch": "nx build --watch --configuration development",
    "test": "nx test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.0",
    "@angular/cdk": "^17.0.1",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/material": "^17.0.1",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@ngrx/component-store": "~16.0.0",
    "@ngrx/effects": "~16.0.0",
    "@ngrx/entity": "~16.0.0",
    "@ngrx/router-store": "~16.0.0",
    "@types/node": "^20.10.0",
    "bootstrap": "^5.3.2",
    "crypto-js": "^4.2.0",
    "ngx-bootstrap": "^11.0.2",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.3",
    "@angular-devkit/core": "^17.0.3",
    "@angular-devkit/schematics": "^17.0.3",
    "@angular/cli": "^17.0.3",
    "@angular/compiler-cli": "^17.0.0",
    "@ngrx/schematics": "~16.0.0",
    "@ngrx/store": "^17.0.1",
    "@ngrx/store-devtools": "~16.0.0",
    "@nx/angular": "17.1.3",
    "@nx/workspace": "17.1.3",
    "@schematics/angular": "^17.0.3",
    "@types/crypto-js": "^4.2.1",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "jasmine-marbles": "~0.9.1",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "nx": "17.1.3",
    "typescript": "~5.2.2"
  },
  "nx": {
    "targets": {}
  }
}

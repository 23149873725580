import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService }     from 'ngx-bootstrap/modal';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormUserLoginComponent } from '../../user/form-user-login/form-user-login.component';
import { Observable } from 'rxjs';
import { UserService } from '../../user/user-service/user.service';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss']
})
export class BurgerMenuComponent implements OnInit {
  modalRef?: BsModalRef;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;

  isLoggedIn$: Observable<boolean>;
  
  constructor(
    private modalService: BsModalService,
    private userService: UserService
  ) {
    this.isLoggedIn$ = this.userService.isLoggedIn$;
  }

  ngOnInit(): void {}

  Connecter(event: Event): void {
    console.log(this.Connecter.name);
    event.stopPropagation();

    this.modalRef = this.modalService.show(FormUserLoginComponent, {});
    console.log(this.modalRef);
    
    this.trigger.closeMenu();
  }

  Deconnecter(): void {
    console.log(this.Deconnecter.name);
    //this.userService.logoutUser();
    this.trigger.closeMenu();
  }

  VoirProfil(event: Event): void {
    console.log(this.VoirProfil.name);
    event.stopPropagation();
    //this.router.navigate(['/user-summary']);
    this.trigger.closeMenu();
  }
}

<form [formGroup]="loginForm" (ngSubmit)="onConnect()">
    <div>
      <label for="username">identifiant</label>
      <input type="text" id="username" formControlName="username">
      <div *ngIf="loginForm.get('username')?.invalid && loginForm.get('username')?.touched">
        <!-- Error message for code field -->
        <div *ngIf="loginForm.get('username')?.errors?.['required']">
          Identifiant requis.
        </div>
        <div *ngIf="loginForm.get('username')?.errors?.['pattern']">
          Identifiant incorrect.
        </div>
      </div>
    </div>
    <div>
      <label for="password">Mot de passe</label>
      <input type="password" id="password" formControlName="password">
      <div *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched">
        <!-- Error message for code field -->
        <div *ngIf="loginForm.get('password')?.errors?.['required']">
          Mot de passe requis.
        </div>
        <div *ngIf="loginForm.get('password')?.errors?.['pattern']">
          Mot de passe incorrect.
        </div>
      </div>
    </div>
    <button type="submit" [disabled]="!loginForm.valid">Connecter</button>
    <button type="button" (click)="onCancel()">Annuler</button>
</form>
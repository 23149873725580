export const environment = {
    production: false,
    version: require("../../package.json").version,
    api1: {
        connexion: {
            url: "https://alfredapi1.int.do-good.fr"
        },
        routes: {
            login: "/user/login"
        }
    }
}

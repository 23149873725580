export const NAV_STORE_KEY = 'navStore';

export interface INavItem {
    id: TopicIds | SectionIds;
    label: string;
    labelClass?: string;
    labelStyles?: { [key: string]: string };   
    subLabel?: string;
    subLabelClass?: string;
    subLabelStyles?: { [key: string]: string };
    parentId?: string;                      // pour une section, c'est un topic
    route?: string,
    rolesAllowed?: string[],
    rolesDenied?: string[]
  }
  
  export enum TopicIds {
    Topic_Location = 'TOPICLOCATION',
    Topic_Patrimoine = "TOPICPATRIMOINE",
    Topic_Admin = 'TOPICADMIN'
  }
  
  // à adapter
  export enum SectionIds {
    Section_Utilisateurs = 'SECTION_UTILISATEURS',
    Section_Locataires = 'SECTION_LOCATAIRES',
    Section_Logement = 'SECTION_LOGEMENTS'
  }

import { Action, State, createReducer, on } from '@ngrx/store';
import { loginSuccess } from './user.actions';
import { IUserState, initialUserState } from './user.state';

const reducer = createReducer(
  initialUserState,
  on(loginSuccess, (state, { username, idsession, rolecodes }) => ({
    ...state,
    username: username,
    idsession: idsession,
    rolecodes: rolecodes
  })
  // Ajoutez d'autres gestionnaires d'action ici
  )
);

export function userReducer(state: IUserState | undefined, action: Action) {
  return reducer(state, action);
}

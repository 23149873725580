import { SectionIds, TopicIds } from "./nav.share"

export interface INavState {
    idTopic: TopicIds | SectionIds,
    idSection: TopicIds | SectionIds,
    route: string
}

export const initialNavState: INavState = {
    idTopic: TopicIds.Topic_Location,
    idSection: SectionIds.Section_Locataires,
    route: ''
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IUserState } from './user.state';
import { USER_STORE_KEY } from './user.share';

export const selectUserState = createFeatureSelector<IUserState>(USER_STORE_KEY);

export const selectRoleCodes = createSelector(
  selectUserState,
  (state: IUserState) => state.rolecodes
);

// Vous pouvez créer des sélecteurs supplémentaires de manière similaire

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from '../user-service/user.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-form-user-login',
  templateUrl: './form-user-login.component.html',
  styleUrls: ['./form-user-login.component.scss']
})
export class FormUserLoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
    'username': new FormControl(null, [Validators.required, Validators.pattern(/^\S*$/)]), // Pas d'espaces autorisés
    'password': new FormControl(null, [Validators.required, Validators.pattern(/^\S*$/)]) // Pas d'espaces autorisés
  });

  constructor(
    public modalRef: BsModalRef, 
    private store: Store,
    private userService: UserService
  ) {}
  
  ngOnInit(): void {}

  onConnect() {
    if (this.loginForm?.valid) {
      const { username, password } = this.loginForm.value;
      const hashedPassword = CryptoJS.MD5(password).toString();

      // demande de login
      this.userService.login(username, hashedPassword).subscribe(
        response => {
          console.log("login ok", response);
          // dispatch action login ok
          this.userService.handleLoginResponse(response);
          this.modalRef.hide();
        },
        error => {
          console.log("erreur", error);
        }
      );

      // Close the modal after successful login if needed
      
    }
  }

  onCancel() {
    this.loginForm?.reset();
      this.modalRef.hide();
  }
}

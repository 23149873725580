import { Component, isDevMode, HostListener } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  version = environment.version;
  isDesktopView: boolean = true;
  isDevMode: boolean = false;

  constructor() {
    this.isDevMode = isDevMode();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    this.isDesktopView = window.innerWidth > 768;
  }

}
